<div class="container w-100 vh-100">
  <div class="mb-3">
    <ng-container *ngIf="!isLoggedIn">
      <div class="row mt-3">
        <div class="navbar-brand col-md-12 text-center">
          <img src="../../../../assets/images/LBIG Logo For Life 725x150.png" width="300" />
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-md-12 text-center">
          <div class="nav-container">
            <nav class="navbar navbar-expand annuityloginmenu">
              <div class="container-fluid justify-content-center">
                <ul class="nav navbar-nav" id="annuitymenu">
                  <li class="nav-item mr-3">
                    <a class="nav-link annuityloginlink" id="login-link" data-toggle="tab" href="#" (click)="login()">
                      <i class="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Agent Login
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link annuityloginlink" id="signup-link" data-toggle="tab" href="#" (click)="signup()">
                      <i class="fas fa-user-edit"></i>&nbsp;&nbsp;First-Time Sign Up
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="row mt-2">
      <div class="col-sm-12 text-center">
        <h2 class="text-primary"><b>Current Annuity Interest Rates ({{ currentDate | date:'MM/dd/yyyy' }})</b></h2>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12 text-center">
        For further rate details please refer to these guides:<br />
        <a class="btn-tool" target="_blank" href="{{channelProduct.productPDF}}"><i class="fad fa-briefcase"></i>&nbsp;Product Portfolio</a>&nbsp;
        <a class="btn-tool" target="_blank" href="{{channelProduct.statePDF}}"><i class="fad fa-map-marked-alt"></i>&nbsp;State Availability</a>
      </div>
    </div>
    <div class="nav-container mb-3">
      <nav class="navbar navbar-expand navbar-light bg-light">
        <div class="container-fluid justify-content-center">
          <ul class="nav navbar-nav" id="listMenu">
            <li class="nav-item">
              <a class="nav-link router-link-exact-active" id="NewBusinessList-tab" data-toggle="tab" href="#NewBusinessList" (click)="onClickTabSelect('#NewBusinessList-tab')">New Business</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="RenewalList-tab" data-toggle="tab" href="#RenewalList" (click)="onClickTabSelect('#RenewalList-tab')">Renewal</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="tab-content clearfix" id="tabListPanes">
      <div class="tab-pane justify-content-center" id="NewBusinessList">
        <ng-container *ngIf="newBusinessDeferredRatesRowData.length > 0">
          <div class="row justify-content-center">
            <h4><b>CLIC Deferred Annuities</b></h4>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="col-lg-12 col-xl-9">
              <ag-grid-angular class="ag-theme-alpine"
                               [rowData]="newBusinessDeferredRatesRowData"
                               [columnDefs]="ratesDeferredNBRatesColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               [domLayout]="domLayout"
                               [autoGroupColumnDef]="autoGroupColumnDef"
                               [groupDefaultExpanded]="groupDefaultExpanded"
                               rowSelection='none'
                               (gridReady)="onGridReady($event)"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [suppressRowClickSelection]="true"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="newBusinessImmediateRatesRowData.length > 0">
          <div class="row justify-content-center">
            <h4><b>CLIC Immediate Annuities</b></h4>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="col-lg-12 col-xl-9">
              <ag-grid-angular class="ag-theme-alpine"
                               [rowData]="newBusinessImmediateRatesRowData"
                               [columnDefs]="ratesImmediateNBRatesColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               [domLayout]="domLayout"
                               [autoGroupColumnDef]="autoGroupColumnDef"
                               [groupDefaultExpanded]="groupDefaultExpanded"
                               rowSelection='none'
                               (gridReady)="onGridReady1($event)"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [suppressRowClickSelection]="true"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
            <div class="justify-content-left ">
              <div class="px-2 mt-2">
                <h5><i>* Current monthly income factors provided are based on a $100,000 non-qualified premium for a 65 year old male.</i></h5>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="newBusinessFixedIndexRatesRowData.length > 0">
          <div class="row justify-content-center">
            <h4><b>CLIC Fixed Index Annuities</b></h4>
          </div>
          <div class="row justify-content-center mb-3">
            <div class="col-lg-12 col-xl-9">
              <ag-grid-angular class="ag-theme-alpine"
                               [rowData]="newBusinessFixedIndexRatesRowData"
                               [columnDefs]="fixedIndexRatesColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               [domLayout]="domLayout"
                               [autoGroupColumnDef]="autoGroupColumnDef"
                               [groupDefaultExpanded]="groupDefaultExpanded"
                               rowSelection='none'
                               (gridReady)="onGridReady2($event)"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [suppressRowClickSelection]="true"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="tab-pane justify-content-center" id="RenewalList">
        <div class="row justify-content-center">
          <h4><b>CLIC Deferred Annuities</b></h4>
        </div>
        <ng-container *ngIf="renewalDeferredRatesRowData.length > 0">
          <div class="row justify-content-center mb-3">
            <div class="col-lg-12 col-xl-9">
              <ag-grid-angular class="ag-theme-alpine"
                               [rowData]="renewalDeferredRatesRowData"
                               [columnDefs]="ratesDeferredRenewalRatesColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               [domLayout]="domLayout"
                               [autoGroupColumnDef]="autoGroupColumnDef"
                               [groupDefaultExpanded]="groupDefaultExpanded"
                               rowSelection='none'
                               (gridReady)="onGridReady3($event)"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [suppressRowClickSelection]="true"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="renewalImmediateRatesRowData.length > 0">
          <div class="row justify-content-center">
            <h4><b>CLIC Immediate Annuities</b></h4>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-12 col-xl-9">
              <ag-grid-angular class="ag-theme-alpine"
                               [rowData]="renewalImmediateRatesRowData"
                               [columnDefs]="ratesImmediateRenewalRatesColumnDefs"
                               [defaultColDef]="defaultColumnDef"
                               [domLayout]="domLayout"
                               [autoGroupColumnDef]="autoGroupColumnDef"
                               [groupDefaultExpanded]="groupDefaultExpanded"
                               rowSelection='none'
                               (gridReady)="onGridReady4($event)"
                               [overlayNoRowsTemplate]="noRowsTemplate"
                               [suppressRowClickSelection]="true"
                               [ag-grid-resize]>
              </ag-grid-angular>
            </div>
            <div class="justify-content-left ">
              <div class="px-2 mt-2">
                <h5><i>* Current monthly income factors provided are based on a $100,000 non-qualified premium for a 65 year old male.</i></h5>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 mx-auto text-center" style="padding-top:10px">
      <p>This site is intended for sole use by bank representatives of Liberty Bankers Insurance Group and any of its affiliate companies. By entering the site, you agree to abide by its <a routerLink="/terms" target="new">Terms of Use.</a></p>
    </div>
  </div>
</div>
