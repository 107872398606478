<div class="row justify-content-center" *ngIf="showHeaderText">
  <div class="col-md-10">
    <div class="formheadertext text-left">
      <p innerHTML={{headerText}}></p>
    </div>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-10">
    <div class="card card-secondary forms-box">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Document Upload</span>
        </h4>
      </div>
      <form (ngSubmit)="uploadFile()" enctype="multipart/form-data">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>File</label>
                <input type="file" class="form-control" (change)="onSelectFile($event)" id="file" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>File Category</label>
                <select id="fileTarget" name="fileTarget" class="form-control" (change)="onFileTargetChange($event)" [(ngModel)]="fileTargetId">
                  <option value="0" selected>Select Category</option>
                  <option *ngFor="let category of fileTargets$ | async" value="{{category.fileTargetId}}">
                    {{category.fileCategory}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Owner Name</label>
                <input type="text" class="form-control" id="clientName" (keyup)="onClientNameChange($event)" [(ngModel)]="clientName">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Policy Number (if applicable)</label>
                <input type="text" class="form-control" id="policyNumber" (change)="onPolicyNumberChange($event)" [(ngModel)]="policyNumber">
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="progress-bar progress-bar-info progress-bar-striped"
                   role="progressbar"
                   attr.aria-valuenow="{{ progress }}"
                   aria-valuemin="0"
                   aria-valuemax="100"
                   [ngStyle]="{ width: progress + '%' }">
                {{ progress }}% Uploaded
              </div>
            </div>
            <div class="col-md-1">
              <span *ngIf="showUploadComplete">
                <i class="fad fa-check-circle fa-2x" style="--fa-primary-color: green; --fa-secondary-color: green; "></i>
              </span>
            </div>
          </div>
          <div *ngIf="message" class="alert alert-danger" role="alert">{{ message }}</div>
        </div>
        <div class="card-footer">
          <div class="text-center">
            <input type="submit" class="btn btn-secondary input-block-level" value="Upload" [disabled]="disableUpload">&nbsp;
            <input type="reset" class="btn btn-secondary input-block-level" value="Reset" (click)="resetUpload()">
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="col-md-10">
    <div class="card card-secondary">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>{{uploadRowCount === 1 ? uploadRowCount + " Upload" : uploadRowCount + " Uploads"}}</span>
        </h4>
      </div>
      <div class="card-body">
        <div *ngIf="!showSpinner" style="overflow-x:scroll;">
          <ag-grid-angular style="height: 300px;"
                           class="ag-theme-alpine"
                           [rowData]="rowData"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="defaultColumnDef"
                           rowSelection='none'
                           (gridReady)="onGridReady($event)"
                           [suppressRowClickSelection]="true"
                           [overlayNoRowsTemplate]="noRowsTemplate"
                           [ag-grid-resize]>
          </ag-grid-angular>
        </div>
        <div class="row justify-content-center" *ngIf="showSpinner">
          <component-loading></component-loading>
        </div>
      </div>
    </div>
  </div>
</div>
