<div class="row justify-content-center">
  <div class="col-md-10">
    <div class="card card-secondary">
      <div class="card-header">
        <h4 class="card-title w-100">
          <span>Agent Associations (Admin)</span>
        </h4>
      </div>
    </div>
    <ng-container *ngIf="showAddButton">
      <div class="text-right mb-2">
        <input type="button" class="btn btn-secondary input-block-level" value="Add Agent Link Association" (click)="onAddClicked()">
      </div>
    </ng-container>
    <div class="row admin-box" *ngIf="showList">
      <div class="col-md-12">
        <ag-grid-angular style="height: 500px;"
                         class="ag-theme-alpine"
                         [rowData]="associations$ | async"
                         [columnDefs]="columnDefs"
                         [defaultColDef]="defaultColumnDef"
                         rowSelection='single'
                         [suppressRowClickSelection]="true"
                         (gridReady)="onGridReady($event)"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         (rowClicked)="onRowClicked($event)"
                         ag-grid-resize>
        </ag-grid-angular>
      </div>
    </div>
    <div class="card card-primary" id="AssociationDetails" *ngIf="showDetail">
      <div class="card-header">
        <div class="row">
          <div class="col-md-10">
            <h4 class="card-title w-100">
              <span>Agent Link Association Detail</span>
            </h4>
          </div>
          <div class="col-md-2 my-auto text-right">
            <i class="fas fa-2x fa-times-circle" style="cursor:pointer" (click)="closeDetail()"></i>
          </div>
        </div>
      </div>
      <ng-container *ngIf="showUpdateForm">
        <form #associationUpdateForm="ngForm" (ngSubmit)="updateAssociation(associationUpdateForm.value)">
          <ng-container *ngIf="selectedAssociation$ | async as associations">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Linked Agent ID</label>
                    <input type="text" class="form-control" name="agentID" id="agentID" readonly [(ngModel)]="associations.agentID">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Linked Agent Name</label>
                    <input type="text" class="form-control" name="agentName" id="agentName" readonly [(ngModel)]="associations.agentName">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Associated Agent ID</label>
                    <input type="text" class="form-control" name="associatedAgentID" id="associatedAgentID" [(ngModel)]="associations.associatedAgentID"
                           (keyup)="changeAssociatedID($event)">
                    <input type="button" class="btn btn-secondary mt-2" value="Check Agent ID"
                           (click)="checkAgentID(associationUpdateForm.controls.associatedAgentID, associationUpdateForm.controls.associatedAgentName)">
                    &nbsp;&nbsp;&nbsp;
                    <span class="align-bottom" *ngIf="showAgentIDFound">
                      <i class="fad fa-check-circle fa-2x" style="--fa-primary-color: green; --fa-secondary-color: green; "></i>&nbsp;
                      Agent ID Found!
                    </span>
                    <span class="align-bottom" *ngIf="showAgentIDNotFound">
                      <i class="fad fa-times-circle fa-2x" style="--fa-primary-color: darkred; --fa-secondary-color: darkred; "></i>&nbsp;
                      Agent ID Not Found!
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Associated Agent Name</label>
                    <input type="text" class="form-control" name="associatedAgentName" id="associatedAgentName" readonly [(ngModel)]="associations.associatedAgentName">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="card-footer">
            <div class="text-center">
              <input type="submit" class="btn btn-secondary input-block-level" name="updateButton" id="updateButton" value="Update" [disabled]="disableUpdate">&nbsp;&nbsp;&nbsp;
              <input type="button" class="btn btn-danger input-block-level" value="Delete" (click)="deleteAssociation()">
            </div>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="showAddForm">
        <form #associationAddForm="ngForm" (ngSubmit)="addAssociation(associationAddForm.value)">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Linked Agent ID</label>
                  <select name="agentID" id="agentID" class="form-control" (change)="onAgentChange(associationAddForm.controls.agentID)" [(ngModel)]="agentId">
                    <option value="" selected>Select Linked Agent</option>
                    <option *ngFor="let agent of unassociatedLinkedAgents$ | async" value="{{agent.agentId}}">
                      {{agent.agentId}} - {{agent.agentName}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Associated Agent ID</label>
                  <input type="text" class="form-control" name="associatedAgentID" id="associatedAgentID" [(ngModel)]="associatedAgentID"
                         (keyup)="changeAssociatedID($event)">
                  <input type="button" class="btn btn-secondary mt-2" value="Check Agent ID"
                         (click)="checkAgentID(associationAddForm.controls.associatedAgentID, associationAddForm.controls.associatedAgentName)">
                  &nbsp;&nbsp;&nbsp;
                  <span class="align-bottom" *ngIf="showAgentIDFound">
                    <i class="fad fa-check-circle fa-2x" style="--fa-primary-color: green; --fa-secondary-color: green; "></i>&nbsp;
                    Agent ID Found!
                  </span>
                  <span class="align-bottom" *ngIf="showAgentIDNotFound">
                    <i class="fad fa-times-circle fa-2x" style="--fa-primary-color: darkred; --fa-secondary-color: darkred; "></i>&nbsp;
                    Agent ID Not Found!
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Associated Agent Name</label>
                  <input type="text" class="form-control" name="associatedAgentName" id="associatedAgentName" readonly [(ngModel)]="associatedAgentName">
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-center">
              <input type="submit" class="btn btn-secondary input-block-level" name="addButton" id="addButton" value="Add" [disabled]="disableAdd">&nbsp;&nbsp;&nbsp;
              <input type="reset" class="btn btn-danger input-block-level" value="Reset">
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>
