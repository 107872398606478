import { Component, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
  styles: [
  ]
})
export class ContactPageComponent implements AfterViewInit {

  ngAfterViewInit(): void {
    $('#menu li a').click(function () {
      if (this.parentElement?.className.indexOf('dropdown') == -1) {
        $('#menu').find("*").removeClass("router-link-exact-active");
        $(this).addClass('router-link-exact-active');
      }
    });
  }

}
