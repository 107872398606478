<!--src/app/components/nav-bar/nav-bar.component.html-->
<div class="nav-container mb-3">
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <a routerLink="/home">
      <div class="navbar-brand"><img src="../../../../assets/images/LBIG_Logo_tag.png" width="200" /></div>
    </a>
    <div class="container-fluid">
      <ul class="nav navbar-nav">
        <li class="nav-item" *ngIf="auth.user$">
          <a routerLink="/home"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Home
          </a>
        </li>
        <li class="nav-item" *ngIf="profileComplete">
          <a routerLink="/briefcase"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            My Briefcase
          </a>
        </li>
        <li class="nav-item" *ngIf="auth.user$">
          <a routerLink="/products"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Products
          </a>
        </li>
        <li class="nav-item" *ngIf="profileComplete">
          <a routerLink="/forms"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Forms
          </a>
        </li>
        <li class="nav-item" *ngIf="auth.user$">
          <a routerLink="/training"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Training
          </a>
        </li>
        <li class="nav-item" *ngIf="profileComplete && showEAppLink">
          <a routerLink="/eapp"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Quote & Apply
          </a>
        </li>
        <li class="nav-item" *ngIf="profileComplete && showContactUsLink">
          <a routerLink="/contact"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Contact Us
          </a>
        </li>
        <li class="nav-item" *ngIf="profileComplete && showDocumentLink">
          <a routerLink="/documents"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Document Upload
          </a>
        </li>
        <li class="nav-item" *ngIf="profileComplete && showCollectionsLink">
          <a routerLink="/collections"
             class="nav-link"
             routerLinkActive="router-link-exact-active">
            Collections
          </a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right" *ngIf="profileComplete">
        <li class="nav-item" aria-expanded="false">
          <div class="nav-link">
            <i class="fad fa-search-minus " id="zoomOut" (click)="zoomOut();"></i>
            {{currZoomValue}}
            <i class="fad fa-search-plus " id="zoomIn" (click)="zoomIn();"></i>
          </div>
        </li>
        <li class="nav-item dropdown" *ngIf="notices && notices.length === 0">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            &nbsp;&nbsp;<i class="fad fa-siren fa-lg"></i>
            <span class="badge badge-{{systemStatusColor}} navbar-badge" style="top: -10px !important; position: relative !important;" [innerHTML]="systemStatusBadgeText"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <span class="dropdown-item">
              <div class="media">
                <div class="media-body">
                  <p class="text-sm">{{systemStatus}}<span class="float-right text-sm text-success"><i class="fad fa-star"></i></span></p>
                </div>
              </div>
            </span>
          </div>
        </li>
        <li class="nav-item dropdown" *ngIf="notices && notices.length > 0">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            &nbsp;&nbsp;<i class="fad fa-siren fa-lg"></i>
            <span class="badge badge-{{systemStatusColor}} navbar-badge" style="top: -10px !important; position: relative !important;" [innerHTML]="systemStatusBadgeText"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <span class="dropdown-item" *ngFor="let notice of notices">
              <!--<a class="nav-link"><small></small></a>-->
              <div class="media">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    <b>{{notice.description}}</b>
                    <span class="float-right text-sm text-warning"><i class="fad fa-star"></i></span>
                  </h3>
                  <p class="text-sm" innerHTML={{notice.message}}></p>
                </div>
              </div>
            </span>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <span><b>{{profile.channelPreference}}</b></span>&nbsp;&nbsp;<span><i class="fas fa-angle-down"></i></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <span class="dropdown-item" *ngFor="let channel of profile.channelList">
              <a class="nav-link" [ngClass]="{'active': channel.text === profile.channelPreference}" (click)="changeChannel(channel.value, channel.text);"><small>{{channel.text}}</small></a>
            </span>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <b>{{profile.displayName}}</b>&nbsp;&nbsp;<span><i class="fas fa-angle-down"></i></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right" style="left: inherit; right: 0px;">
            <span class="dropdown-item">
              <a routerLink="/profile" class="nav-link"><small>Profile</small></a>
            </span>
            <span class="dropdown-item" *ngIf="showImpersonation">
              <a routerLink="/userlist" class="nav-link"><small>Switch User</small></a>
            </span>
            <span class="dropdown-item" *ngIf="showAdmin">
              <a routerLink="/admin" class="nav-link"><small>Administration</small></a>
            </span>
            <span class="dropdown-item"><a class="nav-link" (click)="logout();"><small>Logout</small></a></span>
          </div>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right" *ngIf="!profileComplete">
        <li class="nav-item dropdown" *ngIf="notices && notices.length === 0">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            &nbsp;&nbsp;<i class="fad fa-siren"></i>
            <span class="badge badge-{{systemStatusColor}} navbar-badge" style="top: -10px !important; position: relative !important;" [innerHTML]="systemStatusBadgeText"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <span class="dropdown-item">
              <div class="media">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    <b>{{systemMessage}}</b>
                    <span class="float-right text-sm text-success"><i class="fad fa-star"></i></span>
                  </h3>
                </div>
              </div>
            </span>
          </div>
        </li>
        <li class="nav-item dropdown" *ngIf="notices && notices.length > 0">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            &nbsp;&nbsp;<i class="fad fa-siren"></i>
            <span class="badge badge-{{systemStatusColor}} navbar-badge" style="top: -10px !important; position: relative !important;" [innerHTML]="systemStatusBadgeText"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <span class="dropdown-item" *ngFor="let notice of notices">
              <div class="media">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    <b>{{notice.description}}</b>
                    <span class="float-right text-sm text-warning"><i class="fad fa-star"></i></span>
                  </h3>
                  <p class="text-sm" innerHTML={{notice.message}}></p>
                </div>
              </div>
            </span>
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <b>{{profile.displayName}}</b>
          </a>
          <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right" style="left: inherit; right: 0px;">
            <span class="dropdown-item"><a class="nav-link" (click)="logout();"><i class="fas fa-sign-out-alt"></i>&nbsp;<small>Logout</small></a></span>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>

