import { Component } from '@angular/core';

@Component({
  selector: 'app-adminPage',
  templateUrl: './documents.component.html',
  styles: [
  ]
})
export class DocumentsPageComponent {

  constructor() { }

}
