import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { ColDef, ColGroupDef, RowSelectedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { AgentDetail } from '../../../models/agentDetail';
import { agentLinkAssociation } from '../../../models/association';
import { CloudStorageService } from '../../../services/cloud-storage';
import { OnPremStorageService } from '../../../services/onprem-storage';
import { SiteParameterService } from '../../../services/siteparameter.service';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styles: [
  ]
})
export class AssociationsComponent implements OnInit {
  public showList: boolean = true;
  public showDetail: boolean = false;
  public showAddButton: boolean = true;
  public showUpdateForm: boolean = false;
  public showAddForm: boolean = false;
  public disableUpdate: boolean = true;
  public disableAdd: boolean = true;
  public showAgentIDFound: boolean = false;
  public showAgentIDNotFound: boolean = false;

  public associations$?: Observable<agentLinkAssociation[]>;
  public selectedAssociation$?: Observable<agentLinkAssociation>;
  public unassociatedLinkedAgents$?: Observable<AgentDetail[]>;
  private gridApi: any;
  public noRowsTemplate: string = "";
  private gridColumnApi: any;

  private agentLinkAssociationID: number = 0;
  private agentID: string = "";
  private associatedAgentID: string = "";
  private associatedAgentName: string = "";
  private channelID: number = 0;
  private regionID: number = 0;
  private associatedRegionID: number = 0;

  public columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'Linked Agents',
      children: [
        { field: 'agentID', headerName: 'Agent ID', width: 100 },
        { field: 'agentName', headerName: 'Agent Name' },
      ],
    },
    {
      headerName: 'Associated Agents',
      children: [
        { field: 'associatedAgentID', headerName: 'Agent ID', width: 100 },
        { field: 'associatedAgentName', headerName: 'Agent Name' }
      ],
    },
  ];

  defaultColumnDef = {
    sortable: true,
    resizable: true,
  };

  constructor(private onPremService: OnPremStorageService, private siteParmService: SiteParameterService, private cloudStorageService: CloudStorageService,
    private datePipe: DatePipe) {
    this.noRowsTemplate = this.siteParmService.getGridNoResults();
  }

  ngOnInit(): void {
    this.listAssociations();
    this.getUnassociatedLinkedAgentList();
  }

  listAssociations() {
    this.associations$ = this.onPremService.getAssociations().pipe(map(response => response.data));
  }

  getUnassociatedLinkedAgentList(): void {
    this.unassociatedLinkedAgents$ = this.onPremService.getUnassociatedLinkedAgentList().pipe(
      map(apiReponse => apiReponse.data));
  }

  onRowClicked(event: RowSelectedEvent) {
    this.selectedAssociation$ = this.onPremService.getAssociation(event.data.agentLinkAssociationID)
      .pipe(map(response => response.data[0]));

    this.agentLinkAssociationID = event.data.agentLinkAssociationID;
    this.associatedRegionID = event.data.associatedRegionID;

    this.showList = false;
    this.showDetail = true;
    this.showAddButton = false;
    this.showUpdateForm = true;
    this.showAddForm = false;
  }

  onAddClicked() {
    this.agentID = "";
    this.associatedAgentID = "";
    this.associatedAgentName = "";
    this.showList = false;
    this.showDetail = true;
    this.showAddForm = true;
    this.showUpdateForm = false;
    this.showAddButton = false;
  }

  closeDetail() {
    this.showAgentIDFound = false;
    this.showAgentIDNotFound = false;
    this.disableAdd = true;
    this.disableUpdate = true;

    this.showDetail = false;
    this.showList = true;
    this.showAddButton = true;
  }

  changeAssociatedID(event: any) {
    this.disableAdd = true;
    this.disableUpdate = true;
    this.showAgentIDFound = false;
    this.showAgentIDNotFound = false;
  }

  addAssociation(formData: any): void {
    this.onPremService.addAssociation(this.agentID, this.channelID, this.regionID, formData.associatedAgentID, this.associatedRegionID)
      .pipe(take(1))
      .subscribe(apiResponse => {
        let agentLinkID = apiResponse.data;
        let date = new Date();
        date.setDate(date.getDate() - 1);
        let yesterdayDateString = this.datePipe.transform(date, 'MM/dd/yyyy');
        if (yesterdayDateString !== null) {
          this.cloudStorageService.updateDateModified(agentLinkID, yesterdayDateString)
            .pipe(take(1))
            .subscribe();
        }
      }
      );

    this.showAgentIDFound = false;
    this.showAgentIDNotFound = false;
    this.disableAdd = true;

    this.showDetail = false;
    this.showList = true;
    this.showAddButton = true;
  }

  updateAssociation(formData: any): void {
    this.onPremService.updateAssociation(this.agentLinkAssociationID, formData.associatedAgentID, this.associatedRegionID)
      .pipe(take(1))
      .subscribe(apiResponse => {
        let agentLinkID = apiResponse.data;
        let date = new Date();
        date.setDate(date.getDate() - 1);
        let yesterdayDateString = this.datePipe.transform(date, 'MM/dd/yyyy');
        if (yesterdayDateString !== null) {
          this.cloudStorageService.updateDateModified(agentLinkID, yesterdayDateString)
            .pipe(take(1))
            .subscribe();
        }
      }
      );

    this.showAgentIDFound = false;
    this.showAgentIDNotFound = false;
    this.disableUpdate = true;

    this.showDetail = false;
    this.showList = true;
    this.showAddButton = true;
  }

  deleteAssociation() {
    if (confirm("Are you sure you want to delete this Agent Association?")) {
      this.onPremService.deleteAssociation(this.agentLinkAssociationID)
        .pipe(take(1))
        .subscribe();

      this.showDetail = false;
      this.showList = true;
      this.showAddButton = true;
    }
  }

  checkAgentID(formAgentIdCtrl: AbstractControl, formAgentNameCtrl: AbstractControl) {
    var agentId = formAgentIdCtrl.value;

    this.onPremService.getAgentDetail(agentId)
      .pipe(take(1))
      .subscribe(apiResponse => {
        if (apiResponse.data.length === 0) {
          this.showAgentIDFound = false;
          this.showAgentIDNotFound = true;
          this.disableAdd = true;
          this.disableUpdate = true;
        }
        else {
          this.showAgentIDFound = true;
          this.showAgentIDNotFound = false;
          this.disableAdd = false;
          this.disableUpdate = false;

          if (this.agentID === "")
            this.disableAdd = true;
        }

        formAgentIdCtrl.setValue(apiResponse.data[0].agentID);
        formAgentNameCtrl.setValue(apiResponse.data[0].name);
        this.associatedRegionID = apiResponse.data[0].regionID;
      });
  }

  onAgentChange(formAgentIdCtrl: AbstractControl) {
    var agentId = formAgentIdCtrl.value;

    if (agentId != "") {
      this.onPremService.getAgentDetail(agentId)
        .pipe(take(1))
        .subscribe(apiResponse => {
          this.agentID = apiResponse.data[0].agentID;
          this.channelID = apiResponse.data[0].channelID;
          this.regionID = apiResponse.data[0].regionID;
        });
      this.disableAdd = false;
    }
    else {
      this.disableAdd = true;
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }
}
